<script setup lang="ts"></script>

<template>
  <div class="content-container">
    <slot></slot>
  </div>
</template>
<style>
.content-container {
  padding: 100px 25px 25px 25px;
}
</style>
